import {
  updateState,
  validate_otp,
  validate_name,
  validate_email,
  validate_telephone,
  validate_password,
  validate_licence,
  validate_companyname,
  validate_genericlicence,
  validate_longcopy,
  validate_message,
} from "../modules/Generic";

function syntheticInputEvent(eventTarget, value) {
  var target = document.getElementById(eventTarget);
  var event = new Event("input", { bubbles: true });
  event.simulated = true;
  target.value = value;
  target.dispatchEvent(event);
}

function syntheticClickEvent(
  event,
  eventTarget,
  theValue,
  theHandeInputChange
) {
  let target = document.getElementById(eventTarget);

  event.preventDefault();

  if (target.type === "checkbox") {
    target.checked = theValue;
  } else {
    target.value = theValue;
  }

  theHandeInputChange(event, false, false, target);
}

function autoExpand(event, theTarget) {
  if (event) {
    var el = event.target;

    el.style.height = "inherit";
    if (el.value != "") {
      el.style.height = el.scrollHeight + "px";
    }
  } else if (theTarget) {
    var el = document.getElementById(theTarget);

    el.style.height = "inherit";
    if (el.value != "") {
      el.style.height = el.scrollHeight + "px";
    }
  }
}

function focusMe(event) {
  const theEl = event.target;

  if (
    this.props.appMode == "mobile" &&
    device &&
    (device.platform == "iOS" || device.version == "iPhone")
  ) {
    setTimeout(() => {
      theEl.scrollIntoView({ alignToTop: true, behavior: "smooth" });
    }, 550);
  } else {
    theEl.scrollIntoView({ alignToTop: true, behavior: "smooth" });
  }
}

function handleInputChange(event, theCallback, theMode, forceTarget) {

  const updateState = this.updateState || this.props.updateState || updateState;
  const target = forceTarget || event.target;
  let value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;

  if (target.type === "checkbox" || target.type === "radio") {
    event.stopPropagation();
  } else {
    event.preventDefault();
  }

  if (
    name === "job.originAddress.formattedAddress" ||
    name === "job.destinationAddress.formattedAddress" ||
    name === "quickEdit.originAddress.formattedAddress" ||
    name === "quickEdit.destinationAddress.formattedAddress"
  ) {
    this.autoExpand(event);
  } else if (name === "email") {
    value = value.replace(/\s/g, "");
  }

  var oldValue = false;

  const validateInputIfSet = (forceName, forceValue) => {
    if (this.state.validation[name] != "notset") {
      validateInput(
        updateState,
        forceName || name,
        forceValue || value,
        theCallback
      );
    } else if (theCallback) {
      theCallback();
    }
  };

  const inputChangeCallback = () => {
    if (
      name == "otp.one" ||
      name == "otp.two" ||
      name == "otp.three" ||
      name == "otp.four" ||
      name == "otp.five" ||
      name == "otp.six"
    ) {
      const selectNext = () => {
        if (name == "otp.one") {
          document.getElementById("otp.two").focus();
        } else if (name == "otp.two") {
          document.getElementById("otp.three").focus();
        } else if (name == "otp.three") {
          document.getElementById("otp.four").focus();
        } else if (name == "otp.four") {
          document.getElementById("otp.five").focus();
        } else if (name == "otp.five") {
          document.getElementById("otp.six").focus();
        }
      };

      let theSplitOtp = value.split("");

      if (theSplitOtp.length == 6) {
        let theUpdates = {
          otp: {
            one: theSplitOtp[0].toUpperCase(),
            two: theSplitOtp[1].toUpperCase(),
            three: theSplitOtp[2].toUpperCase(),
            four: theSplitOtp[3].toUpperCase(),
            five: theSplitOtp[4].toUpperCase(),
            six: theSplitOtp[5].toUpperCase(),
          },
        };

        updateState(false, theUpdates, () => {
          validateInputIfSet(
            "otp",
            this.state.otp.one +
            this.state.otp.two +
            this.state.otp.three +
            this.state.otp.four +
            this.state.otp.five +
            this.state.otp.six
          );
        });
      } else {
        updateState(false, { [name]: theSplitOtp[0].toUpperCase() }, () => {
          selectNext();
          validateInputIfSet(
            "otp",
            this.state.otp.one +
            this.state.otp.two +
            this.state.otp.three +
            this.state.otp.four +
            this.state.otp.five +
            this.state.otp.six
          );
        });
      }
    } else if (name == "job.jobSpec.serviceDescription") {
      validate_message(value, "book", (updatedFlags, updatedBlocks) => {
        updateState(
          false,
          {
            ['jobSpecFlags["' + name + '"]']: updatedFlags,
            ['jobSpecBlocks["' + name + '"]']: updatedBlocks,
          },
          validateInputIfSet
        );
      });
    } else if (name == "myReview.comment") {
      validate_message(value, "review", (updatedFlags, updatedBlocks) => {
        updateState(
          false,
          {
            ["ignore.myReviewFlags"]: updatedFlags,
            ["ignore.myReviewBlocks"]: updatedBlocks,
          },
          validateInputIfSet
        );
      });
    } else if (name == "theReview.comment") {
      validate_message(value, "review", () => { });
    } else if (name == "job.originAddress.formattedAddress") {
      let theUpdates = {
        "job.originAddress": {
          googleValidated: "",
          placeId: "",
          formattedAddress: this.state.job?.originAddress?.formattedAddress,
          streetNumber: "",
          streetName: "",
          postCode: "",
          townCityName: "",
          regionName: "",
          countryName: "United Kingdom",
        },
      };

      this.updateState(false, theUpdates, () => {
        this.getAddresses(
          false,
          this.updateState,
          "ignore.addressSuggestions.originAddress",
          "job.originAddress",
          validateInputIfSet
        );
      });
    } else if (name == "job.destinationAddress.formattedAddress") {
      let theUpdates = {
        "job.destinationAddress": {
          googleValidated: "",
          placeId: "",
          formattedAddress: this.state.job?.destinationAddress?.formattedAddress,
          streetNumber: "",
          streetName: "",
          postCode: "",
          townCityName: "",
          regionName: "",
          countryName: "United Kingdom",
        },
      };

      this.updateState(false, theUpdates, () => {
        this.getAddresses(
          false,
          this.updateState,
          "ignore.addressSuggestions.destinationAddress",
          "job.destinationAddress",
          validateInputIfSet
        );
      });

      // } else if (theCallback) {
      //     theCallback();
      // }
    } else {
      validateInputIfSet();
      //theCallback();
    }

    //         this.findAddress(e, 'originAddress')
    // this.findAddress(e, 'destinationAddress')
  };

  if (theMode != "blocked") {
    updateState(false, { [name]: value }, inputChangeCallback);
  }
}

function handleInputBlur(event, theUpdateState, theCallback) {
  const updateState = this.updateState || this.props.updateState || updateState;

  const target = event.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;

  validateInput(updateState, name, value, theCallback);
}

function otpFucus(event, theCallback) {
  const target = event.target;
  const name = target.name;

  window["otp." + name] = target.value;
  target.value = "";
}

function validateInput(theUpdateState, theName, theValue, theCallback) {
  if (theName == "otp") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length == 6 && validate_otp(theValue),
      },
      theCallback
    );
  } else if (
    theName == "name" ||
    theName == "firstname" ||
    theName == "surname"
  ) {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_name(theValue),
      },
      theCallback
    );
  } else if (theName == "telemail") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length &&
          theValue.length > 2 &&
          (validate_email(theValue) || validate_telephone(theValue)),
      },
      theCallback
    );
  } else if (theName == "telephone") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length &&
          theValue.length > 2 &&
          validate_telephone(theValue),
      },
      theCallback
    );
  } else if (theName == "email") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_email(theValue),
      },
      theCallback
    );
  } else if (theName == "password") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_password(theValue),
      },
      theCallback
    );
  } else if (theName == "drivingLicence") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_licence(theValue),
      },
      theCallback
    );
  } else if (theName == "companyName") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length &&
          theValue.length > 2 &&
          validate_companyname(theValue),
      },
      theCallback
    );
  } else if (theName == "vatNumber") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length &&
          theValue.length > 2 &&
          validate_genericlicence(theValue),
      },
      theCallback
    );
  } else if (theName == "wasteHandlingLicence") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length &&
          theValue.length > 2 &&
          validate_genericlicence(theValue),
      },
      theCallback
    );
  } else if (
    theName == "job.jobSpec.serviceDescription" ||
    theName == "quickEdit.jobSpec.serviceDescription"
  ) {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_longcopy(theValue),
      },
      theCallback
    );
  } else if (theName == "myReview.comment" || theName == "myReview.comment") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_longcopy(theValue),
      },
      theCallback
    );
  } else if (theName == "theReview.comment") {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_longcopy(theValue),
      },
      theCallback
    );
  } else if (
    theName == "job.jobSpec.serviceDescription" ||
    theName == "quickEdit.jobSpec.serviceDescription"
  ) {
    theUpdateState(
      false,
      { ['validation["' + theName + '"]']: validate_longcopy(theValue) },
      theCallback
    );
  } else if (
    theName == "job.originAddress.formattedAddress" ||
    theName == "quickEdit.originAddress.formattedAddress"
  ) {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_longcopy(theValue),
      },
      theCallback
    );
  } else if (
    theName == "job.destinationAddress.formattedAddress" ||
    theName == "quickEdit.destinationAddress.formattedAddress"
  ) {
    theUpdateState(
      false,
      {
        ['validation["' + theName + '"]']:
          theValue.length && theValue.length > 2 && validate_longcopy(theValue),
      },
      theCallback
    );
  } else if (theCallback) {
    theCallback();
  }
}

export {
  syntheticInputEvent,
  syntheticClickEvent,
  autoExpand,
  focusMe,
  handleInputChange,
  handleInputBlur,
  otpFucus,
  validateInput,
};
