import React from "react";
import $ from "jquery";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";

import TopNav from "./TopNav";
import { getUsers, setUser } from "../modules/Admin";
import { showSpinner, closeSpinner, modal, confirm } from "../modules/Dialogs";
import {
  sessionExpired,
  adminSaveChangesConfirm,
  defaultAvatarBase64,
  adminClientCannotPreactivate,
  adminClientCannotReset,
  serverUrl,
  userImagesFolder,
  imageFormat,
  defaultAvatarImage,
} from "../config/GeneralConfig";
import { toScreen } from "../modules/Routing";
import AuditNotes from "./AuditNotes";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
import { dateProcess } from "../modules/Generic";

class Client extends React.Component {
  constructor(props) {
    super(props);

    this.updateNotes = this.updateNotes.bind(this);

    this.getClient = this.getClient.bind(this);
    this.getClient_done = this.getClient_done.bind(this);
    this.renderJobs = this.renderJobs.bind(this);
    this.saveClient = this.saveClient.bind(this);
    this.saveClient_done = this.saveClient_done.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteClientAvatar = this.deleteClientAvatar.bind(this);
    this.discardChanges = this.discardChanges.bind(this);

    this.state = {
      originalStatus: "",
      client: {
        _id: "",
        short_id: "",
        notes: [],
        accountInfo: {
          username: "",
          surname: "",
          telephone: "",
          telephone: "",
          email: "",
          avatar: false,
          terms: false,
          settings: {
            emailnotifications: false,
            holidaymode: false,
            marketingnotifications: false,
            pushnotifications: false,
          },
        },
        stripeCustomerId: "",
        userType: "",
        status: "",
        jobs: {},
      },
      totals: {
        total: 0,
        open: 0,
        closed: 0,
        agreed: 0,
        paid: 0,
        complete: 0,
        refund: 0,
        transferneeded: 0,
      },
      pagination: {
        page: 1,
        total: 1,
      },
    };
  }

  updateNotes(newNotes, theCallback) {
    if (!_.isEqual(newNotes, this.state.client?.notes)) {
      this.setState(
        {
          ...this.state,
          client: {
            ...this.state.client,
            notes: newNotes,
          },
        },
        theCallback
      );
    } else if (theCallback) {
      theCallback();
    }
  }

  discardChanges(event) {
    if (event) {
      event.preventDefault();
    }

    //this.getClient();

    toScreen(false, this.props.navigate, "/clients");
  }

  getClient() {
    showSpinner();
    const theUser = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
      user: this.props.clientId ?? window?.location?.pathname?.split("/")[2],
      userType: "user",
    };

    getUsers(theUser, this.getClient_done, this.props.userDetails.jwttoken);
  }

  getClient_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);

      toScreen(false, this.props.navigate, "/clients");
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        originalStatus: resJson.theUsers["0"]?.status,
        client: resJson.theUsers["0"],
      });
    }
  }

  saveClient(event) {
    if (event) {
      event.preventDefault();
    }

    confirm(false, adminSaveChangesConfirm, "Cancel", "Save", () => {
      showSpinner();

      var theUser = {
        accountInfo: {
          username: this.props.userDetails.accountInfo.username,
          email: this.props.userDetails.accountInfo.email,
        },
        user: {
          _id: this.state.client._id,
          short_id: this.state.client.short_id,
          notes: this.state.client?.notes,
          accountInfo: {
            username: this.state.client?.accountInfo.username,
            firstname: this.state.client?.accountInfo.firstname,
            surname: this.state.client?.accountInfo.surname,
            telephone: this.state.client?.accountInfo.telephone,
            email: this.state.client?.accountInfo.email,
            settings: {
              emailnotifications:
                this.state.client?.accountInfo.settings.emailnotifications,
              holidaymode: this.state.client?.accountInfo.settings.holidaymode,
              marketingnotifications:
                this.state.client?.accountInfo.settings.marketingnotifications,
              pushnotifications:
                this.state.client?.accountInfo.settings.pushnotifications,
            },
          },
          userType: this.state.client.userType,
          status: this.state.client.status,
        },
      };

      if (this.state.client?.accountInfo.avatar == "") {
        theUser.user.accountInfo.avatar = defaultAvatarBase64;
      }

      setUser(theUser, this.saveClient_done, this.props.userDetails.jwttoken);
    });
  }

  saveClient_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);
    } else if (resStatus == 200) {
      modal("Changes successfully saved", false, () => {
        toScreen(false, this.props.navigate, "/clients");
      });
    }
  }

  /** THIS RENDER JOBS FUNC FOR NEW DESIGN */
  renderJobs(key) {
    const job = this.state.client?.jobs[key];
    var originAddressShort = "";
    if (!job?.originAddress?.formattedAddress) {
      originAddressShort = "";
    } else if (job?.originAddress?.formattedAddress?.length > 18) {
      originAddressShort =
        job?.originAddress?.formattedAddress.substring(0, 15) + "...";
    } else {
      originAddressShort = job?.originAddress?.formattedAddress;
    }

    var destinationAddressShort = "";
    if (!job?.destinationAddress?.formattedAddress) {
      destinationAddressShort = "";
    } else if (job?.destinationAddress?.formattedAddress?.length > 18) {
      destinationAddressShort =
        job?.destinationAddress?.formattedAddress?.substring(0, 15) + "...";
    } else {
      destinationAddressShort = job?.destinationAddress?.formattedAddress;
    }

    return (
      <div className="c-list-entry" key={key}>
        <div className="c-list-entry__primary">
          <b>{job?.jobSpec?.date || "ASAP"}</b> | {job?.status} | From:{" "}
          {originAddressShort} to: {destinationAddressShort}
        </div>
        <button
          type="button"
          className="c-btn c-btn--teal c-btn--small"
          onClick={(e) =>
            toScreen(e, this.props.navigate, "/job/" + job?.short_id)
          }
        >
          view
        </button>
      </div>
    );
  }
  /** THIS RENDER JOBS FUNC FOR NEW DESIGN */
  // renderJobs(key) {
  //   const job = this.state.client?.jobs[key];
  //   console.log(job);

  //   var originAddressShort = "";
  //   if (!job?.originAddress?.formattedAddress) {
  //     originAddressShort = "";
  //   } else if (job?.originAddress?.formattedAddress.length > 12) {
  //     originAddressShort =
  //       job?.originAddress?.formattedAddress.substring(0, 9) + "...";
  //   } else {
  //     originAddressShort = job?.originAddress?.formattedAddress;
  //   }

  //   var destinationAddressShort = "";
  //   if (!job?.destinationAddress?.formattedAddress) {
  //     destinationAddressShort = "";
  //   } else if (job?.destinationAddress?.formattedAddress.length > 12) {
  //     destinationAddressShort =
  //       job?.destinationAddress?.formattedAddress.substring(0, 9) + "...";
  //   } else {
  //     destinationAddressShort = job?.destinationAddress?.formattedAddress;
  //   }

  //   let userName =
  //     job._creator &&
  //     job._creator.accountInfo &&
  //     job._creator.accountInfo.username
  //       ? job._creator.accountInfo.username
  //       : "N/A";

  //   return (
  //     <div
  //       className={
  //         job.isPaused ? "c-list-entry u-color-grey-dark" : "c-list-entry"
  //       }
  //       key={key}
  //     >
  //       <div className="c-list-entry__name">{job?.status}</div>
  //       <div className="c-list-entry__quotes">
  //         <b>{_.size(job?.quotes)}</b>
  //       </div>
  //       <div className="c-list-entry__jobdate">
  //         {job?.jobSpec?.dateAndTime && job?.jobSpec?.dateAndTime?.standardDate
  //           ? dateProcess(
  //               job?.jobSpec?.dateAndTime?.standardDate,
  //               "printDateNice"
  //             )
  //           : job?.jobSpec?.dateAndTime &&
  //             job?.jobSpec?.dateAndTime?.type == "notsure"
  //           ? "(Not sure)"
  //           : job?.jobSpec?.date
  //           ? job?.jobSpec?.date
  //           : "ASAP"}{" "}
  //         {job?.jobSpec?.dateAndTime &&
  //         job?.jobSpec?.dateAndTime?.type == "thisweek"
  //           ? "(W/C)"
  //           : null}
  //       </div>
  //       <div className="c-list-entry__location">
  //         {job?.originAddress?.townCityName ||
  //           job?.originAddress?.regionName ||
  //           job?.originAddress?.formattedAddress}
  //         {job?.jobSpec?.serviceType != "dispose" ? " to " : null}
  //         {job?.destinationAddress?.townCityName ||
  //           job?.destinationAddress?.regionName ||
  //           job?.destinationAddress?.formattedAddress ||
  //           ""}
  //       </div>
  //       <div className="c-list-entry__created">
  //         {job.creationDate} at {job.creationTime}
  //       </div>

  //       <button
  //         type="button"
  //         className="c-btn c-btn--teal c-btn--small"
  //         onClick={(e) =>
  //           toScreen(e, this.props.navigate, "/job/" + job.short_id)
  //         }
  //       >
  //         view
  //       </button>
  //     </div>
  //   );
  // }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {};

    if (
      name === "client.status" &&
      value === "preactivation" &&
      this.state.originalStatus !== "preactivation"
    ) {
      modal(adminClientCannotPreactivate);
    } else if (
      name === "client.status" &&
      value === "resetting" &&
      this.state.originalStatus !== "resetting"
    ) {
      modal(adminClientCannotReset);
    }
    if (
      name === "client.accountInfo.settings.pushnotifications" ||
      name === "client.accountInfo.settings.emailnotifications" ||
      name === "client.accountInfo.settings.marketingnotifications" ||
      name === "client.accountInfo.settings.holidaymode"
    ) {
      let splitString = name.split(".");
      let settingType = splitString[splitString.length - 1];
      this.setState(
        {
          ...this.state,
          client: {
            ...this.state.client,
            accountInfo: {
              ...this.state.client?.accountInfo,
              settings: {
                ...this.state.client?.accountInfo.settings,
                [settingType]: value,
              },
            },
          },
        },
        inputChangeCallback
      );
    } else if (name != "client.accountInfo.terms") {
      if (name.split(".").length == 1) {
        oldValue = this.state[name];
        this.setState(
          {
            [name]: value,
          },
          inputChangeCallback
        );
      } else if (name.split(".").length == 2) {
        oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
        this.setState(
          {
            [name.split(".")[0]]: {
              ...this.state[name.split(".")[0]],
              [name.split(".")[1]]: value,
            },
          },
          inputChangeCallback
        );
      } else if (name.split(".").length == 3) {
        oldValue =
          this.state[name.split(".")[0]][name.split(".")[1]][
            name.split(".")[2]
          ];
        this.setState(
          {
            [name.split(".")[0]]: {
              ...this.state[name.split(".")[0]],
              [name.split(".")[1]]: {
                ...this.state[name.split(".")[0]][name.split(".")[1]],
                [name.split(".")[2]]: value,
              },
            },
          },
          inputChangeCallback
        );
      }
    }
  }

  deleteClientAvatar(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      client: {
        ...this.state.client,
        accountInfo: {
          ...this.state.client?.accountInfo,
          avatar: "",
        },
      },
    });
  }

  sortJobs(event, theSort) {
    if (event) {
      event.preventDefault();
    }

    var tmpSort;

    if (theSort == "status") {
      if (this.state.sort === '{"jobSpec.status":1}') {
        tmpSort = '{"jobSpec.status":-1}';
      } else {
        tmpSort = '{"jobSpec.status":1}';
      }
    } else if (theSort == "jobdate") {
      if (
        this.state.sort ===
        '{"jobSpec.dateAndTime.standardDate":1,"jobSpec.standardDate":1}'
      ) {
        tmpSort =
          '{"jobSpec.dateAndTime.standardDate":-1,"jobSpec.standardDate":-1}';
      } else {
        tmpSort =
          '{"jobSpec.dateAndTime.standardDate":1,"jobSpec.standardDate":1}';
      }
    } else if (theSort == "location") {
      if (this.state.sort === '{"originAddress.formattedAddress":1}') {
        tmpSort = '{"originAddress.formattedAddress":-1}';
      } else {
        tmpSort = '{"originAddress.formattedAddress":1}';
      }
    } else if (theSort == "created") {
      if (this.state.sort === '{"creationDate":1}') {
        tmpSort = '{"creationDate":-1}';
      } else {
        tmpSort = '{"creationDate":1}';
      }
    } else if (theSort == "quotes") {
      if (this.state.sort === '{"quotes":1}') {
        tmpSort = '{"quotes":-1}';
      } else {
        tmpSort = '{"quotes":1}';
      }
    }

    const inputChangeCallback = () => {
      this.getClient();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        sort: tmpSort,
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  filterJob(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClient();
    };

    this.setState(
      {
        ...this.state,
        search: "",
        pagination: {
          page: 1,
          total: 1,
        },
      },
      inputChangeCallback
    );
  }

  nextPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClient();
    };

    if (this.state.pagination.page < this.state.pagination.total) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page + 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  prevPage(event) {
    if (event) {
      event.preventDefault();
    }

    const inputChangeCallback = () => {
      this.getClient();
    };

    if (this.state.pagination.page > 1) {
      this.setState(
        {
          ...this.state,
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page - 1,
          },
        },
        inputChangeCallback
      );
    }
  }

  componentWillMount() {
    //
  }
  componentDidMount() {
    this.getClient();
  }
  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Client</h1>

        <div className="c-hr"></div>
        {this.state.client ? (
          <>
            <div className="c-subheader-info-panel">
              <div className="c-subheader-info-panel__primary">
                ID: {this.state.client?.short_id}
              </div>
            </div>

            <div className="c-hr"></div>

            <div className="l-row">
              {/*
	                <div className="l-col-18">
	                    <div className="c-avatar">
	                        <div className="c-avatar__pic-wrap">
	                            <img src={this.state.client?.accountInfo.avatar && this.state.client?.accountInfo.avatar!='' ? serverUrl+userImagesFolder+'/'+this.state.client.short_id+'/'+this.state.client?.accountInfo.avatar+imageFormat : defaultAvatarImage} alt="avatar" className="c-avatar__pic" onError={(e)=>{e.target.src = defaultAvatarImage}}/>
	                        </div>
	                        <button onClick={(e)=>this.deleteClientAvatar(e)}>Delete Avatar</button>                    
	                    </div>
					</div>
		*/}
              <div className="l-col-59">
                <div className="c-checkbox-radio">
                  <input
                    type="radio"
                    id="pending"
                    name="client.status"
                    value="preactivation"
                    onChange={this.handleInputChange}
                    checked={this.state.client?.status === "preactivation"}
                  />
                  <label htmlFor="pending">Pending</label>
                </div>
                <div className="c-checkbox-radio">
                  <input
                    type="radio"
                    id="active"
                    name="client.status"
                    value="active"
                    onChange={this.handleInputChange}
                    checked={this.state.client?.status === "active"}
                  />
                  <label htmlFor="active">Active</label>
                </div>
                {/*<div className="c-checkbox-radio c-checkbox-radio--no-right-border">
	                        <input type="radio" id="resetting" name="client.status" value="resetting" onChange={this.handleInputChange} checked={this.state.client.status === 'resetting'}/>
	                        <label htmlFor="resetting">Resetting</label>
	                    </div>*/}
                <div className="c-checkbox-radio c-checkbox-radio--no-right-border">
                  <input
                    type="radio"
                    id="blocked"
                    name="client.status"
                    value="blocked"
                    onChange={this.handleInputChange}
                    checked={this.state.client?.status === "blocked"}
                  />
                  <label htmlFor="blocked">Blocked</label>
                </div>
              </div>
              <div className="l-col-41">
                <button
                  className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--red"
                  onClick={(e) => this.saveClient(e)}
                >
                  Save Changes
                </button>
                <button
                  className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs  c-btn--grey"
                  onClick={(e) => this.discardChanges(e)}
                >
                  Discard Changes
                </button>
                <button
                  className="c-btn c-btn--small c-btn--full-width  c-btn--green"
                  onClick={(e) =>
                    toScreen(
                      e,
                      this.props.navigate,
                      "/message/" + this.state.client?.short_id,
                      false,
                      {
                        receiverId: this.state.client?._id,
                        receiverShortId: this.state.client?.short_id,
                        receiverType: this.state.client?.userType,
                        userShortId1: "admin",
                        userShortId2: this.state.client?.short_id,
                        username2: this.state.client?.accountInfo?.username,
                        selectedPage:
                          window.history.state.usr?.selectedPage ?? 1,
                        selectedFilter:
                          window.history.state.usr?.selectedFilter ??
                          "adminuserunread",
                        previousPath:
                          window.history.state.usr?.previousPath ??
                          window.location.pathname,
                      }
                    )
                  }
                >
                  Contact Client
                </button>
              </div>
            </div>

            <div className="c-hr"></div>

            <div className="l-row">
              <div className="l-col-66">
                <form action="">
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">First name:</label>
                    <input
                      type="text"
                      value={this.state.client?.accountInfo?.firstname}
                      onChange={this.handleInputChange}
                      name="client.accountInfo.firstname"
                      placeholder="First name"
                      className="c-input-wide__input"
                    />
                  </div>
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Surname:</label>
                    <input
                      type="text"
                      value={this.state.client?.accountInfo?.surname}
                      onChange={this.handleInputChange}
                      name="client.accountInfo.surname"
                      placeholder="Surname"
                      className="c-input-wide__input"
                    />
                  </div>
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Username:</label>
                    <input
                      type="text"
                      value={this.state.client?.accountInfo?.username}
                      onChange={this.handleInputChange}
                      name="client.accountInfo.username"
                      placeholder="Username"
                      className="c-input-wide__input"
                    />
                  </div>
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Email:</label>
                    <input
                      type="text"
                      value={this.state.client?.accountInfo?.email}
                      onChange={this.handleInputChange}
                      name="client.accountInfo.email"
                      placeholder="Email"
                      className="c-input-wide__input"
                    />
                  </div>
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Telephone:</label>
                    <input
                      type="text"
                      value={this.state.client?.accountInfo?.telephone}
                      onChange={this.handleInputChange}
                      name="client.accountInfo.telephone"
                      placeholder="Telephone"
                      className="c-input-wide__input"
                    />
                  </div>
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">
                      Stripe customer ref:
                    </label>
                    <span>
                      <b>
                        {this.state.client?.stripeCustomerId &&
                        this.state.client?.stripeCustomerId != "" ? (
                          <a
                            href={
                              "https://dashboard.stripe.com/customers/" +
                              this.state.client?.stripeCustomerId
                            }
                            target="_blank"
                          >
                            {this.state.client?.stripeCustomerId}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </b>
                    </span>
                  </div>
                  <div className="c-input-wide c-input-wide--checkbox">
                    <input
                      type="checkbox"
                      className="c-input-wide__checkbox-radio-input"
                      id="terms"
                      onChange={this.handleInputChange}
                      name="client.accountInfo.terms"
                      checked={this.state.client?.accountInfo?.terms}
                    />
                    <label className="c-input-wide__label" htmlFor="terms">
                      Terms agreed:
                    </label>
                  </div>

                  <br />
                  <h4>Notification Settings:</h4>

                  <div className="c-input-wide">
                    <label className="c-input-wide__label-alt">
                      Push notifications :
                    </label>
                    <span className="c-input-wide__copy-alt">
                      <input
                        type="checkbox"
                        className="c-toggle-switch-small__input"
                        name="client.accountInfo.settings.pushnotifications"
                        id="client.accountInfo.settings.pushnotifications"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.client?.accountInfo?.settings
                            .pushnotifications === true
                        }
                      />
                      <label
                        htmlFor="client.accountInfo.settings.pushnotifications"
                        className="c-toggle-switch-small__label"
                      >
                        <span className="u-visually-hidden">
                          Push notifications
                        </span>
                      </label>
                    </span>
                  </div>

                  <div className="c-input-wide">
                    <label className="c-input-wide__label-alt">
                      Email notifications :
                    </label>
                    <span className="c-input-wide__copy-alt">
                      <input
                        type="checkbox"
                        className="c-toggle-switch-small__input"
                        name="client.accountInfo.settings.emailnotifications"
                        id="client.accountInfo.settings.emailnotifications"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.client?.accountInfo?.settings
                            .emailnotifications === true
                        }
                      />
                      <label
                        htmlFor="client.accountInfo.settings.emailnotifications"
                        className="c-toggle-switch-small__label"
                      >
                        <span className="u-visually-hidden">
                          Email notifications
                        </span>
                      </label>
                    </span>
                  </div>

                  <div className="c-input-wide">
                    <label className="c-input-wide__label-alt">
                      Marketing notifications :
                    </label>
                    <span className="c-input-wide__copy-alt">
                      <input
                        type="checkbox"
                        className="c-toggle-switch-small__input"
                        name="client.accountInfo.settings.marketingnotifications"
                        id="client.accountInfo.settings.marketingnotifications"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.client?.accountInfo?.settings
                            .marketingnotifications === true
                        }
                      />
                      <label
                        htmlFor="client.accountInfo.settings.marketingnotifications"
                        className="c-toggle-switch-small__label"
                      >
                        <span className="u-visually-hidden">
                          Marketing notifications
                        </span>
                      </label>
                    </span>
                  </div>

                  <div className="c-input-wide">
                    <label className="c-input-wide__label-alt">
                      Holiday mode :
                    </label>
                    <span className="c-input-wide__copy-alt">
                      <input
                        type="checkbox"
                        className="c-toggle-switch-small__input"
                        name="client.accountInfo.settings.holidaymode"
                        id="client.accountInfo.settings.holidaymode"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.client?.accountInfo.settings
                            .holidaymode === true
                        }
                      />
                      <label
                        htmlFor="client.accountInfo.settings.holidaymode"
                        className="c-toggle-switch-small__label"
                      >
                        <span className="u-visually-hidden">Holiday mode</span>
                      </label>
                    </span>
                  </div>
                </form>
              </div>

              <div className="l-col-33">
                <AuditNotes
                  ref="auditNotes"
                  notes={this.state.client?.notes}
                  updateNotes={this.updateNotes}
                />
              </div>
            </div>

            <div className="l-row l-row">
              <div className="l-col-100">
                <h2 className="u-margin-bottom-0">Jobs</h2>
              </div>
            </div>

            <div className="c-hr"></div>

            {/* START NEW DESIGN JOBS SECTION */}
            {/* <div className="l-row">
              <div className="l-col-100 u-padding-top-0 u-padding-bottom-0">
                <div className="filter-bar">
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="all"
                      name="filter"
                      value="all"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "all"}
                    />
                    <label htmlFor="all">All ({this.state.totals.total})</label>
                  </div>
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="open"
                      name="filter"
                      value="open"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "open"}
                    />
                    <label htmlFor="open">
                      Open ({this.state.totals.open})
                    </label>
                  </div>
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="paid"
                      name="filter"
                      value="paid"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "paid"}
                    />
                    <label htmlFor="paid">
                      Paid ({this.state.totals.paid})
                    </label>
                  </div>
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="complete"
                      name="filter"
                      value="complete"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "complete"}
                    />
                    <label htmlFor="complete">
                      Complete ({this.state.totals.complete})
                    </label>
                  </div>
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="closed"
                      name="filter"
                      value="closed"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "closed"}
                    />
                    <label htmlFor="closed">
                      Closed ({this.state.totals.closed})
                    </label>
                  </div>
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="refund"
                      name="filter"
                      value="refund"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "refund"}
                    />
                    <label
                      htmlFor="refund"
                      className={
                        this.state.totals.refund && this.state.totals.refund > 0
                          ? "u-color-red"
                          : null
                      }
                    >
                      Refund Requested ({this.state.totals.refund})
                    </label>
                  </div>
                  <div className="c-checkbox-radio-block">
                    <input
                      type="radio"
                      id="transferneeded"
                      name="filter"
                      value="transferneeded"
                      onChange={this.handleInputChange}
                      checked={this.state.filter === "transferneeded"}
                    />
                    <label
                      htmlFor="transferneeded"
                      className={
                        this.state.totals.transferneeded &&
                        this.state.totals.transferneeded > 0
                          ? "u-color-red"
                          : null
                      }
                    >
                      Transfer Needed ({this.state.totals.transferneeded})
                    </label>
                  </div>
                </div>
              </div>

              <div className="l-col-100">
                <div className="c-pagination">
                  <button
                    type="button"
                    className="c-pagination__prev"
                    onClick={(e) => this.prevPage(e)}
                  >
                    &lt;&lt; Prev
                  </button>

                  <div className="c-pagination__quotes"></div>
                  <div className="c-pagination__page-count">
                    {this.state.pagination.page} / {this.state.pagination.total}
                  </div>

                  <div className="c-pagination__quotes">Quotes</div>

                  <button
                    type="button"
                    className="c-pagination__next"
                    onClick={(e) => this.nextPage(e)}
                  >
                    Next &gt;&gt;
                  </button>
                </div>
                <div className="c-hr u-margin-bottom-0"></div>

                <div>
                  <div className="c-list-entry c-list-entry__header">
                    <div className="c-list-entry__name c-list-filter">
                      <button
                        className={
                          this.state.sort == '{"jobSpec.status":-1}'
                            ? "c-list-filter__button c-list-filter__button--icon-up"
                            : this.state.sort == '{"jobSpec.status":1}'
                            ? "c-list-filter__button c-list-filter__button--icon-down"
                            : "c-list-filter__button c-list-filter__button--faded"
                        }
                        onClick={(e) => this.sortJobs(e, "status")}
                      >
                        Status
                      </button>
                    </div>
                    <div className="c-list-entry__quotes c-list-filter">
                      <button
                        className={
                          this.state.sort == '{"quotes":-1}'
                            ? "c-list-filter__button c-list-filter__button--icon-up"
                            : this.state.sort == '{"quotes":1}'
                            ? "c-list-filter__button c-list-filter__button--icon-down"
                            : "c-list-filter__button c-list-filter__button--faded"
                        }
                        onClick={(e) => this.sortJobs(e, "quotes")}
                      >
                        Quotes
                      </button>
                    </div>
                    <div className="c-list-entry__jobdate c-list-filter">
                      <button
                        className={
                          this.state.sort ==
                          '{"jobSpec.dateAndTime.standardDate":1,"jobSpec.standardDate":1}'
                            ? "c-list-filter__button c-list-filter__button--icon-up"
                            : this.state.sort ==
                              '{"jobSpec.dateAndTime.standardDate":-1,"jobSpec.standardDate":-1}'
                            ? "c-list-filter__button c-list-filter__button--icon-down"
                            : "c-list-filter__button c-list-filter__button--faded"
                        }
                        onClick={(e) => this.sortJobs(e, "jobdate")}
                      >
                        Job date
                      </button>
                    </div>
                    <div className="c-list-entry__location c-list-filter">
                      <button
                        className={
                          this.state.sort ==
                          '{"originAddress.formattedAddress":-1}'
                            ? "c-list-filter__button c-list-filter__button--icon-up"
                            : this.state.sort ==
                              '{"originAddress.formattedAddress":1}'
                            ? "c-list-filter__button c-list-filter__button--icon-down"
                            : "c-list-filter__button c-list-filter__button--faded"
                        }
                        onClick={(e) => this.sortJobs(e, "location")}
                      >
                        Location
                      </button>
                    </div>
                    <div className="c-list-entry__created c-list-filter">
                      <button
                        className={
                          this.state.sort == '{"creationDate":-1}'
                            ? "c-list-filter__button c-list-filter__button--icon-up"
                            : this.state.sort == '{"creationDate":1}'
                            ? "c-list-filter__button c-list-filter__button--icon-down"
                            : "c-list-filter__button c-list-filter__button--faded"
                        }
                        onClick={(e) => this.sortJobs(e, "created")}
                      >
                        Created
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  {Object.keys(this.state?.client?.jobs)?.map(this.renderJobs)}
                </div>
                <div className="c-hr u-margin-bottom-0"></div>
                <div className="c-pagination">
                  <button
                    type="button"
                    className="c-pagination__prev"
                    onClick={(e) => this.prevPage(e)}
                  >
                    &lt;&lt; Prev
                  </button>
                  {this.state.pagination.page} / {this.state.pagination.total}
                  <button
                    type="button"
                    className="c-pagination__next"
                    onClick={(e) => this.nextPage(e)}
                  >
                    Next &gt;&gt;
                  </button>
                </div>
              </div>
            </div> */}
            {/* END NEW DESIGN JOBS SECTION */}

            {/* START OLD DESIGN JOBS SECTION */}
            <div className="l-row">
              <div className="l-col-33"></div>
              <div className="l-col-66">
                {_.size(this.state.client?.jobs) == 0
                  ? "No jobs for this client."
                  : Object.keys(this.state.client?.jobs).map(this.renderJobs)}
              </div>
            </div>
            {/* END OLD DESIGN JOBS SECTION */}
          </>
        ) : (
          modal("Client user details not found", "Something went wrong!", () =>
            window.history.back()
          )
        )}
      </div>
    );
  }
}

Client.contextTypes = {
  router: PropTypes.object,
};

Client.propTypes = {
  userDetails: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Client);
