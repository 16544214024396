import React from "react";
import { serverCall } from "./ServerComms";
import { modal } from "./Dialogs";

function getResponseString(pResStatus, pErrorString) {
  let objError = errObjects.find(
    (errObject) => errObject.errCode === pResStatus
  );
  if (objError) {
    return objError.errString;
  } else {
    return pErrorString;
  }
}

export const getClientsDriversCategoryCount = (
  params,
  theCallback,
  theJWTToken
) => {
  let apiEndPoint =
    params.type === "clients"
      ? "getClientCount"
      : params.type === "drivers"
      ? "getDriverCount"
      : "";
  const API_URL = `/api/admin/${apiEndPoint}?type=${params.type}&category=${params.category}`;
  serverCall(
    "GET",
    API_URL,
    null,
    function (resStatus, resJson) {
      let responseString;
      if (resStatus == "200" || resStatus == 200) {
        if (resJson.message) {
          responseString = resJson.message;
        }
        return theCallback(resStatus, responseString, resJson);
      } else {
        let responseString = getResponseString(resStatus, resJson.errorString);
        return theCallback(resStatus, responseString, false);
      }
    },
    theJWTToken
  );
};

export const getCityCounts = (params, theCallback, theJWTToken) => {
  const API_URL = `/api/admin/getDriverCount?type=${params.type}&category=${params.category}&city=${params.city}`;
  serverCall(
    "GET",
    API_URL,
    null,
    function (resStatus, resJson) {
      let responseString;
      if (resStatus == "200" || resStatus == 200) {
        if (resJson.message) {
          responseString = resJson.message;
        }
        return theCallback(resStatus, responseString, resJson);
      } else {
        let responseString = getResponseString(resStatus, resJson.errorString);
        return theCallback(resStatus, responseString, false);
      }
    },
    theJWTToken
  );
};

export const getAllCities = (theCallback, theJWTToken) => {
  serverCall(
    "GET",
    "/api/admin/cities",
    null,
    function (resStatus, resJson) {
      let responseString;
      if (resStatus == "200" || resStatus == 200) {
        if (resJson.message) {
          responseString = resJson.message;
        }
        return theCallback(resStatus, responseString, resJson);
      } else {
        let responseString = getResponseString(resStatus, resJson.errorString);
        return theCallback(resStatus, responseString, false);
      }
    },
    theJWTToken
  );
};

export const sendPushNotification = (payloads, theCallback, theJWTToken) => {
  serverCall(
    "POST",
    `/api/admin/sendPushNotification`,
    payloads,
    function (resStatus, resJson) {
      let responseString;
      if (resStatus == "200" || resStatus == 200) {
        if (resJson.message) {
          responseString = resJson.message;
        }
        return theCallback(resStatus, responseString, resJson);
      } else if (resStatus == 409) {
        modal(resJson.message, "Similar job is active");
      } else if (resStatus !== 200) {
        modal(resJson.message, "Something went wrong!");
      } else {
        let responseString = getResponseString(resStatus, resJson.errorString);
        return theCallback(resStatus, responseString, false);
      }
    },
    theJWTToken
  );
};
