import React from "react";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: "",
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  toggleOpen() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  selectOption(option) {
    this.setState({
      selectedOption: option.label,
      isOpen: false, // Close dropdown after selection
    });
    this.props.handleDeepLinkOptionChange(option);
  }

  renderOptionList(options) {
    const { selectedOption } = this.state;

    return (
      <div className="sub-title-dropdown">
        <ul>
          {options.map((option) => {
            // Check if it's a group of options
            if (option.options) {
              return (
                <li className="sub-menu" key={option.label}>
                  <span className="label">{option.label}</span>
                  {this.renderOptionList(option.options)}
                </li>
              );
            }
            return (
              <li
                className={`${
                  selectedOption === option.label ? "selected" : ""
                }`}
                key={option.value}
                onClick={() => this.selectOption(option)}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { isOpen, selectedOption } = this.state;

    // Create dropdown options and filter out any undefined values
    const dropdownOptions = [
      {
        label: "Homescreen",
        options: [{ label: "Homescreen", value: "home" }],
      },
      this.props.selectedUserType === "drivers" && {
        label: "Jobs",
        options: [
          { label: "New Jobs", value: "jobs" },
          { label: "Quoted", value: "jobs" },
          { label: "Dismissed", value: "jobs" },
        ],
      },
      this.props.selectedUserType === "drivers" && {
        label: "Quotes",
        options: [
          { label: "Pending", value: "quotes" },
          { label: "Accepted", value: "quotes" },
          { label: "Completed", value: "quotes" },
          { label: "Archived", value: "quotes" },
        ],
      },
      this.props.selectedUserType === "clients" && {
        label: "Book",
        options: [{ label: "Book", value: "book/servicetype" }],
      },
      this.props.selectedUserType === "clients" && {
        label: "Bookings",
        options: [{ label: "Bookings", value: "bookings" }],
      },
      this.props.selectedUserType === "drivers" && {
        label: "Bookings",
        options: [
          { label: "Upcoming", value: "bookings" },
          { label: "Completed", value: "bookings" },
        ],
      },
      {
        label: "Messages",
        options: [{ label: "Messages", value: "messages" }],
      },
      this.props.selectedUserType === "drivers" && {
        label: "Account",
        options: [
          { label: "Account", value: "account" },
          { label: "Edit Profile", value: "myaccount" },
          { label: "My reviews", value: "myreviews" },
          {
            label: "Invoices",
            value: `${
              this.props.selectedUserType === "clients"
                ? "bookings?filter=complete"
                : this.props.selectedUserType === "drivers"
                ? "quotes?filter=complete"
                : "home"
            }`,
          },
          { label: "Settings", value: "settings" },
          { label: "Contact Support", value: "account" },
        ],
      },
      this.props.selectedUserType === "clients" && {
        label: "Account",
        options: [
          { label: "Account", value: "account" },
          { label: "Edit Profile", value: "myaccount" },
          {
            label: "Invoices",
            value: `${
              this.props.selectedUserType === "clients"
                ? "bookings?filter=complete"
                : this.props.selectedUserType === "drivers"
                ? "quotes?filter=complete"
                : "home"
            }`,
          },
          { label: "Settings", value: "settings" },
          { label: "Contact Support", value: "account" },
        ],
      },
    ].filter(Boolean); // Filter out undefined options

    return (
      <div className="custom-select-dropdown">
        <div className="dropdown-button">
          <button onClick={this.toggleOpen}>
            {this.props.selectedDeepLinkOption?.label || "Select an option"}
          </button>
        </div>
        <div className="custom-option">
          {isOpen && this.renderOptionList(dropdownOptions)}
        </div>
      </div>
    );
  }
}

export default Dropdown;
