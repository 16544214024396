import React from "react";
import $ from "jquery";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";
import TopNav from "./TopNav";
import { setQuote, forcePaymentCompleted } from "../modules/Admin";
import { retrieveQuote } from "../modules/JobAndQuoteManager";
import { showSpinner, closeSpinner, modal, confirm } from "../modules/Dialogs";
import {
  sessionExpired,
  adminSaveChangesConfirm,
  serverUrl,
  quoteImagesFolder,
  thumbSuffix,
  imageFormat,
  defaultCommission,
  adminJobAlreadyClosed,
  adminJobAlreadyComplete,
  adminJobAlreadyPaid,
  adminQuoteAlreadyPaidPrice,
  adminQuoteArchived,
  adminQuoteOpenOther,
  adminQuoteClosed,
  adminQuoteAlreadyPaidSetAny,
  userImagesFolder,
  defaultAvatarImage,
} from "../config/GeneralConfig";
import {
  validate_customerprice,
  calculatePricesWithCommission,
  validate_price,
} from "../modules/Generic";
import { toScreen } from "../modules/Routing";
import AuditNotes from "./AuditNotes";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
class Quote extends React.Component {
  constructor(props) {
    super(props);

    this.updateNotes = this.updateNotes.bind(this);

    this.getQuote = this.getQuote.bind(this);
    this.getQuote_done = this.getQuote_done.bind(this);
    this.renderImages = this.renderImages.bind(this);
    this.saveQuote = this.saveQuote.bind(this);
    this.saveQuote_done = this.saveQuote_done.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.setPrices = this.setPrices.bind(this);

    this.forcePaymentCompleted = this.forcePaymentCompleted.bind(this);
    this.forcePaymentCompleted_done =
      this.forcePaymentCompleted_done.bind(this);

    this.state = {
      creationDate: "",
      originalStatus: "",
      quotePriceForMav: "",
      quotePriceForCommission: "",
      theReview: {
        comment: "",
        stars: 5,
      },
      quote: {
        _id: "",
        short_id: "",
        notes: [],
        _creator: {
          short_id: "",
          accountInfo: {
            username: "",
            avatar: "",
          },
        },
        quoteSpec: {
          price: "",
          itemDescription: "",
          numberMen: "",
          numberVans: "",
          images: [],
          deleteImages: [],
        },
        status: "",
        jobChanged: false,
        job: {
          _id: "",
          status: "",
          paymentType: "",
          mavPayment: "",
          _creator: {
            accountInfo: {
              username: "",
            },
            short_id: "",
          },
          commission: "",
        },
        conversationOpen: false,
      },
    };
  }

  updateNotes(newNotes, theCallback) {
    if (!_.isEqual(newNotes, this.state.quote.notes)) {
      this.setState(
        {
          ...this.state,
          quote: {
            ...this.state.quote,
            notes: newNotes,
          },
        },
        theCallback
      );
    } else if (theCallback) {
      theCallback();
    }
  }

  discardChanges(event) {
    if (event) {
      event.preventDefault();
    }

    toScreen(false, this.props.navigate, "/quotes");
  }

  getQuote() {
    showSpinner();

    const theQuote = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
      short_id: this.props.quoteId ?? window.location.pathname?.split("/")[2],
    };

    retrieveQuote(
      theQuote,
      this.getQuote_done,
      this.props.userDetails.jwttoken
    );
  }

  getQuote_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);

      toScreen(false, this.props.navigate, "/quotes");
    } else if (resStatus == 200) {
      this.setState(
        {
          ...this.state,
          creationDate: resJson.theQuote.creationDate,
          originalStatus: resJson.theQuote.status,
          theReview:
            resJson.ratings && _.size(resJson.ratings) > 0
              ? {
                  stars: resJson.ratings[0].stars,
                  comment: resJson.ratings[0].comment,
                }
              : { stars: false, comment: false },
          quote: {
            ...resJson.theQuote,
            quoteSpec: {
              ...resJson.theQuote.quoteSpec,
              deleteImages: [],
            },
          },
        },
        () => {
          this.setPrices(
            "quote.quoteSpec.price",
            resJson.theQuote.quoteSpec?.price
          );
        }
      );
    }
  }

  saveQuote(event) {
    if (event) {
      event.preventDefault();
    }

    confirm(false, adminSaveChangesConfirm, "Cancel", "Save", () => {
      showSpinner();

      const theQuote = {
        _id: this.state.quote._id,
        short_id: this.state.quote.short_id,
        notes: this.state.quote.notes,
        mavPayment: this.state.quote.job.mavPayment,
        quoteSpec: {
          price: this.state.quote.quoteSpec?.price,
          itemDescription: this.state.quote.quoteSpec.itemDescription,
          numberMen: this.state.quote.quoteSpec.numberMen,
          numberVans: this.state.quote.quoteSpec.numberVans,
          deleteImages: this.state.quote.quoteSpec.deleteImages,
        },
        commission: this.state.quote.job.commission,
        status: this.state.quote.status,
      };

      setQuote(theQuote, this.saveQuote_done, this.props.userDetails.jwttoken);
    });
  }

  saveQuote_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);
    } else if (resStatus == 200) {
      modal(responseString, false, () => {
        toScreen(false, this.props.navigate, "/quotes");
      });
    }
  }

  forcePaymentCompleted(event) {
    if (event) {
      event.preventDefault();
    }

    confirm(
      false,
      "This action will check, if any stripe payment found against this quote. If found the job & quote status will be updated accordingly",
      "Cancel",
      "Continue",
      () => {
        showSpinner();

        const theData = {
          data: {
            quote: this.state.quote._id,
            job: this.state.quote.job._id,
            mav: this.state.quote._creator._id,
          },
        };

        forcePaymentCompleted(
          theData,
          this.forcePaymentCompleted_done,
          this.props.userDetails.jwttoken
        );
      }
    );
  }

  forcePaymentCompleted_done(resStatus, responseString, resJson) {
    closeSpinner();

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);
    } else if (resStatus == 200) {
      modal(responseString, false, () => {
        window.location.reload(true);
      });
    } else if (resStatus == "500") {
      modal(responseString);
    }
  }

  renderImages(key) {
    const image = this.state.quote.quoteSpec.images[key];

    return (
      <div className="l-col-18" key={key}>
        <div className="c-avatar">
          <div className="c-avatar__pic-wrap">
            <img
              src={
                serverUrl +
                quoteImagesFolder +
                "/" +
                this.state.quote._id +
                "/" +
                image +
                thumbSuffix +
                imageFormat
              }
              alt={"image " + image}
              className="c-avatar__pic"
            />
          </div>
          <button value={image} onClick={(e) => this.deleteImage(e)}>
            Delete Image
          </button>
        </div>
      </div>
    );
  }

  setPrices(theName, theValue) {
    var thePrices;

    if (theName == "quote.quoteSpec.price") {
      if (
        validate_customerprice(
          theValue,
          theValue,
          this.state.quote.job.commission || defaultCommission
        )
      ) {
        thePrices = calculatePricesWithCommission(
          false,
          theValue,
          this.state.quote.job.commission || defaultCommission
        );

        this.setState({
          ...this.state,
          quotePriceForCommission: thePrices.commission,
          quotePriceForMav: thePrices.basePrice,
        });
      } else {
        this.setState({
          ...this.state,
          quotePriceForCommission: "",
          quotePriceForMav: "",
        });
      }
    } else if (theName == "quotePriceForMav") {
      if (validate_price(theValue)) {
        thePrices = calculatePricesWithCommission(
          theValue,
          false,
          this.state.quote.job.commission || defaultCommission
        );

        this.setState({
          ...this.state,
          quotePriceForCommission: thePrices.commission,
          quote: {
            ...this.state.quote,
            quoteSpec: {
              ...this.state.quote.quoteSpec,
              price: thePrices.totalPrice,
            },
          },
        });
      } else {
        this.setState({
          ...this.state,
          quotePriceForCommission: "",
          quote: {
            ...this.state.quote,
            quoteSpec: {
              ...this.state.quote.quoteSpec,
              price: "",
            },
          },
        });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var oldValue = false;

    const inputChangeCallback = () => {
      this.setPrices(name, value);

      if (
        (name == "quote.quoteSpec.price" &&
          !validate_customerprice(
            value,
            theValue,
            this.state.quote.job.commission || defaultCommission
          )) ||
        (name == "quotePriceForMav" && !validate_price(value))
      ) {
        this.setState({
          ...this.state,
          quotePriceForCommission: "",
          quotePriceForMav: "",
          quote: {
            ...this.state.quote,
            quoteSpec: {
              ...this.state.quote.quoteSpec,
              price: "",
            },
          },
        });
      }
    };

    // CONTENT CHANGES
    if (this.state.originalStatus === "closed" && name !== "quote.status") {
      modal(adminJobAlreadyClosed);
    } else if (
      this.state.originalStatus === "complete" &&
      name !== "quote.status"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (
      this.state.originalStatus === "complete" &&
      name !== "quote.status"
    ) {
      modal(adminJobAlreadyPaid);
    } else if (
      this.state.originalStatus === "paid" &&
      name !== "quote.status"
    ) {
      modal(adminQuoteAlreadyPaidPrice);
    } else if (this.state.quote.archived && name !== "quote.status") {
      modal(adminQuoteArchived);
      // STATUS CHANGES
    } else if (
      name === "quote.status" &&
      this.state.originalStatus === "open" &&
      value !== "open" &&
      value !== "closed"
    ) {
      modal(adminQuoteOpenOther);
    } else if (
      name === "quote.status" &&
      this.state.originalStatus === "closed" &&
      value !== "open" &&
      value !== "closed"
    ) {
      modal(adminQuoteClosed);
    } else if (
      name === "quote.status" &&
      this.state.originalStatus == "complete" &&
      value !== "complete"
    ) {
      modal(adminJobAlreadyComplete);
    } else if (name === "quote.status" && this.state.quote.archived) {
      modal(adminQuoteArchived);
    } else if (
      name === "quote.status" &&
      this.state.originalStatus === "paid" &&
      value !== "paid"
    ) {
      modal(adminQuoteAlreadyPaidSetAny);
      // OTHER CHANGES
    } else if (name.split(".").length == 1) {
      oldValue = this.state[name];
      this.setState(
        {
          [name]: value,
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 2) {
      oldValue = this.state[name.split(".")[0]][name.split(".")[1]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: value,
          },
        },
        inputChangeCallback
      );
    } else if (name.split(".").length == 3) {
      oldValue =
        this.state[name.split(".")[0]][name.split(".")[1]][name.split(".")[2]];
      this.setState(
        {
          [name.split(".")[0]]: {
            ...this.state[name.split(".")[0]],
            [name.split(".")[1]]: {
              ...this.state[name.split(".")[0]][name.split(".")[1]],
              [name.split(".")[2]]: value,
            },
          },
        },
        inputChangeCallback
      );
    }
  }

  deleteImage(event) {
    if (event) {
      event.preventDefault();
    }

    var newDeleteImages = this.state.quote.quoteSpec.deleteImages.slice(0);
    var newImages = this.state.quote.quoteSpec.images.slice(0);

    if (newDeleteImages.indexOf(parseInt(event.target.value)) == -1) {
      newDeleteImages.push(parseInt(event.target.value));
    }

    if (newImages.indexOf(parseInt(event.target.value)) != -1) {
      newImages.splice(newImages.indexOf(parseInt(event.target.value)), 1);
    }

    this.setState({
      quote: {
        ...this.state.quote,
        quoteSpec: {
          ...this.state.quote.quoteSpec,
          deleteImages: newDeleteImages,
          images: newImages,
        },
      },
    });
  }

  componentWillMount() {
    //
  }
  componentDidMount() {
    this.getQuote();
  }
  componentWillUnmount() {
    //
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Quote</h1>

        <div className="c-hr"></div>

        <div className="c-subheader-info-panel">
          <div className="c-subheader-info-panel__primary u-uppercase">
            ID: {this.state.quote.short_id}
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-18">
            <div className="c-avatar">
              <div className="c-avatar__pic-wrap">
                <img
                  src={
                    /*this.state.quote.quoteSpec.images.length>0 ? serverUrl+quoteImagesFolder+'/'+this.state.quote._id+'/'+this.state.quote.quoteSpec.images[0]+thumbSuffix+imageFormat : */ this
                      .state.quote._creator.accountInfo.avatar &&
                    this.state.quote._creator.accountInfo.avatar != ""
                      ? serverUrl +
                        userImagesFolder +
                        "/" +
                        this.state.quote._creator.short_id +
                        "/" +
                        this.state.quote._creator.accountInfo.avatar +
                        imageFormat
                      : defaultAvatarImage
                  }
                  alt="quote image"
                  className="c-avatar__pic"
                  onError={(e) => {
                    e.target.src = defaultAvatarImage;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="l-col-41">
            Created by :{" "}
            <button
              onClick={(e) =>
                toScreen(
                  e,
                  this.props.navigate,
                  "/mav/" + this.state.quote._creator.short_id
                )
              }
            >
              <b>{this.state.quote._creator.accountInfo.username}</b>
            </button>
            <br />
            For Job :{" "}
            <button
              onClick={(e) =>
                toScreen(
                  e,
                  this.props.navigate,
                  "/job/" + this.state.quote.job.short_id
                )
              }
            >
              <b className="u-uppercase">{this.state.quote.job.short_id}</b>
            </button>
            <br />
            On: {this.state.creationDate}
          </div>
          <div className="l-col-41">
            <button
              className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs c-btn--red"
              onClick={(e) => this.saveQuote(e)}
            >
              Save Changes
            </button>
            <button
              className="c-btn c-btn--small c-btn--full-width  c-btn--grey"
              onClick={(e) => this.discardChanges(e)}
            >
              Discard Changes
            </button>
            {this.state.quote.status === "open" ? (
              <button
                className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs c-btn--orange"
                onClick={(e) => this.forcePaymentCompleted(e)}
              >
                Force payment completion
              </button>
            ) : null}
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-100">
            <div className="c-checkbox-radio">
              <input
                type="radio"
                id="open"
                name="quote.status"
                value="open"
                onChange={this.handleInputChange}
                checked={this.state.quote.status === "open"}
              />
              <label htmlFor="open">
                Open{" "}
                {this.state.quote.status === "open" && this.state.quote.archived
                  ? "(archived)"
                  : null}
              </label>
            </div>
            <div className="c-checkbox-radio">
              <input
                type="radio"
                id="closed"
                name="quote.status"
                value="closed"
                onChange={this.handleInputChange}
                checked={this.state.quote.status === "closed"}
              />
              <label htmlFor="closed">
                Closed{" "}
                {this.state.quote.status === "closed" &&
                this.state.quote.archived
                  ? "(archived)"
                  : null}
              </label>
            </div>

            <div className="c-checkbox-radio">
              <input
                type="radio"
                id="paid"
                name="quote.status"
                value="paid"
                onChange={this.handleInputChange}
                checked={this.state.quote.status === "paid"}
              />
              <label htmlFor="paid">Paid</label>
            </div>
            <div
              className={
                this.state.quote
                  .jobChanged /*|| (this.state.quote.status == 'complete' && this.state.quote.job.paymentType == "fullpayment" && !this.state.quote.job.mavPayment) */
                  ? "c-checkbox-radio c-checkbox-radio"
                  : "c-checkbox-radio c-checkbox-radio--no-right-border"
              }
            >
              <input
                type="radio"
                id="complete"
                name="quote.status"
                value="complete"
                onChange={this.handleInputChange}
                checked={this.state.quote.status === "complete"}
              />
              <label htmlFor="complete">Complete</label>
            </div>
            {this.state.quote.jobChanged ? (
              <div className="c-checkbox-radio-notice">
                <span className="c-checkbox-radio-notice__alert">
                  (Job was updated)
                </span>
              </div>
            ) : null}
            {this.state.quote.declined &&
            this.state.quote.declined.reasonString ? (
              <div className="c-checkbox-radio-notice">
                <span className="c-checkbox-radio-notice">
                  <span className="u-before-icon-exclamation">
                    {" "}
                    Quote declined :
                    <b>
                      {this.state.quote.declined.comment
                        ? '"' + this.state.quote.declined.comment + '"'
                        : this.state.quote.declined.reasonString}
                    </b>
                  </span>
                </span>
              </div>
            ) : null}
            {/*this.state.quote.status == 'complete' && this.state.quote.job.paymentType == "fullpayment" && !this.state.quote.job.mavPayment ? <div className="c-checkbox-radio-notice"><span className="c-checkbox-radio-notice__alert u-before-icon-exclamation">&nbsp;Bank transfer required ("Price quoted") </span>
							<button type="button" className="c-btn c-btn--teal c-btn--small" onClick={(e) => this.bankTransferComplete(e)}>Complete</button>
						</div> : null
					*/}
          </div>
        </div>

        <div className="c-hr"></div>

        <div className="l-row">
          <div className="l-col-66">
            <form action="">
              {this.state.quote.conversationOpen ? (
                <div className="c-input-wide">
                  <button
                    className="c-btn c-btn--full-width u-margin-bottom-m  c-btn--green c-btn--small"
                    onClick={(e) =>
                      toScreen(
                        e,
                        this.props.navigate,
                        "/message/" +
                          this.state.quote.job._creator.short_id +
                          "/" +
                          this.state.quote._creator.short_id,
                        false,
                        {
                          receiverId: this.state.quote?._creator?._id,
                          receiverShortId:
                            this.state.quote?._creator?.short_id,
                          receiverType: "user",
                          userShortId1:  this.state.quote?.job?._creator?.short_id,
                          userShortId2:
                            this.state.quote?.job?._creator?.short_id,
                          username2:
                            this.state.quote?._creator?.accountInfo?.username,
                          selectedPage:
                            window.history.state.usr?.selectedPage ?? 1,
                          selectedFilter:
                            window.history.state.usr?.selectedFilter ??
                            "adminuserunread",
                          previousPath:
                            window.history.state.usr?.previousPath ??
                            window.location.pathname,
                        }
                      )
                    }
                  >
                    View conversation between MAV and Client
                  </button>{" "}
                </div>
              ) : null}
              {this.state.quote.quoteSpec.message &&
              this.state.quote.quoteSpec.message.message ? (
                <div className="c-input-wide">
                  <label className="c-input-wide__label">Message :</label>
                  <span
                    onClick={(e) =>
                      toScreen(
                        e,
                        this.props.navigate,
                        "/message/" +
                          this.state.quote.job._creator.short_id +
                          "/" +
                          this.state.quote._creator.short_id
                      )
                    }
                  >
                    "{this.state.quote.quoteSpec.message.message}"
                    {this.state.quote.quoteSpec.message.moderation &&
                    this.state.quote.quoteSpec.message.moderation.status &&
                    this.state.quote.quoteSpec.message.moderation.status !=
                      "" &&
                    this.state.quote.quoteSpec.message.moderation.status !=
                      "approved" ? (
                      <span>
                        <br />
                        {this.state.quote.quoteSpec.message.moderation &&
                        this.state.quote.quoteSpec.message.moderation.status &&
                        this.state.quote.quoteSpec.message.moderation.status !=
                          "" &&
                        this.state.quote.quoteSpec.message.moderation.status ==
                          "flagged" ? (
                          <span className="u-before-icon-exclamation u-color-red u-font-size-12">
                            &nbsp;Flagged message
                            <br />
                          </span>
                        ) : null}
                        {this.state.quote.quoteSpec.message.moderation &&
                        this.state.quote.quoteSpec.message.moderation.status &&
                        this.state.quote.quoteSpec.message.moderation.status !=
                          "" &&
                        this.state.quote.quoteSpec.message.moderation.status ==
                          "rejected" ? (
                          <span className="u-before-icon-exclamation u-color-grey-dark u-font-size-12">
                            &nbsp;Rejected message
                            <br />
                          </span>
                        ) : null}
                        {this.state.quote.quoteSpec.message.moderation &&
                        this.state.quote.quoteSpec.message.moderation.status &&
                        this.state.quote.quoteSpec.message.moderation.status !=
                          "" &&
                        this.state.quote.quoteSpec.message.moderation.status ==
                          "edited" ? (
                          <span className="u-before-icon-exclamation u-color-grey-dark u-font-size-12">
                            &nbsp;Edited message
                            <br />
                          </span>
                        ) : null}
                      </span>
                    ) : null}
                  </span>
                </div>
              ) : this.state.quote.quoteSpec.itemDescription &&
                this.state.quote.quoteSpec.itemDescription != "" ? (
                <div>
                  <div className="c-input-wide">
                    <label className="c-input-wide__label">Description:</label>
                    <textarea
                      rows="2"
                      type="text"
                      value={this.state.quote.quoteSpec.itemDescription}
                      onChange={this.handleInputChange}
                      name="quote.quoteSpec.itemDescription"
                      placeholder="Description"
                      className="c-input-wide__input"
                    />
                  </div>
                </div>
              ) : null}
              {this.state.quote.quoteSpec.itemDescription &&
              this.state.quote.quoteSpec.message &&
              this.state.quote.quoteSpec.message.moderation &&
              this.state.quote.quoteSpec.message.moderation.status &&
              this.state.quote.quoteSpec.message.moderation.status != "" &&
              this.state.quote.quoteSpec.message.moderation.status !=
                "approved" ? (
                <div
                  className="c-input-wide"
                  onClick={(e) =>
                    toScreen(
                      e,
                      this.props.navigate,
                      "/message/" +
                        this.state.quote.job._creator.short_id +
                        "/" +
                        this.state.quote._creator.short_id
                    )
                  }
                >
                  {" "}
                  <span>
                    {this.state.quote.quoteSpec.message.moderation &&
                    this.state.quote.quoteSpec.message.moderation.status &&
                    this.state.quote.quoteSpec.message.moderation.status !=
                      "" &&
                    this.state.quote.quoteSpec.message.moderation.status ==
                      "flagged" ? (
                      <span className="u-before-icon-exclamation u-color-red u-font-size-12">
                        &nbsp;Flagged message
                      </span>
                    ) : null}
                    {this.state.quote.quoteSpec.message.moderation &&
                    this.state.quote.quoteSpec.message.moderation.status &&
                    this.state.quote.quoteSpec.message.moderation.status !=
                      "" &&
                    this.state.quote.quoteSpec.message.moderation.status ==
                      "rejected" ? (
                      <span className="u-before-icon-exclamation u-color-grey-dark u-font-size-12">
                        &nbsp;Rejected message
                      </span>
                    ) : null}
                    {this.state.quote.quoteSpec.message.moderation &&
                    this.state.quote.quoteSpec.message.moderation.status &&
                    this.state.quote.quoteSpec.message.moderation.status !=
                      "" &&
                    this.state.quote.quoteSpec.message.moderation.status ==
                      "edited" ? (
                      <span className="u-before-icon-exclamation u-color-grey-dark u-font-size-12">
                        &nbsp;Edited message
                      </span>
                    ) : null}
                  </span>
                </div>
              ) : null}

              {this.state.theReview.stars ? (
                <div className="c-input-wide u-padding-bottom-xs">
                  <label className="c-input-wide__label">Client Review :</label>

                  <span className="c-input-wide__copy">
                    <div
                      className="c-rating c-rating--list"
                      onClick={(e) =>
                        toScreen(
                          e,
                          this.props.navigate,
                          "/mav/" +
                            this.state.quote._creator.short_id +
                            "/reviews"
                        )
                      }
                    >
                      <fieldset className="c-rating__wrap">
                        <input
                          tabIndex="-1"
                          className="c-rating__input"
                          type="radio"
                          name="stars1"
                          value="5"
                          checked={this.state.theReview.stars === 5}
                          readOnly
                        />
                        <label className="c-rating__label-read-only-small">
                          <span className="u-visually-hidden">5 stars</span>
                        </label>
                        <input
                          tabIndex="-1"
                          className="c-rating__input"
                          type="radio"
                          name="stars2"
                          value="4"
                          checked={this.state.theReview.stars === 4}
                          readOnly
                        />
                        <label className="c-rating__label-read-only-small">
                          <span className="u-visually-hidden">4 stars</span>
                        </label>
                        <input
                          tabIndex="-1"
                          className="c-rating__input"
                          type="radio"
                          name="stars3"
                          value="3"
                          checked={this.state.theReview.stars === 3}
                          readOnly
                        />
                        <label className="c-rating__label-read-only-small">
                          <span className="u-visually-hidden">3 stars</span>
                        </label>
                        <input
                          tabIndex="-1"
                          className="c-rating__input"
                          type="radio"
                          name="stars4"
                          value="2"
                          checked={this.state.theReview.stars === 2}
                          readOnly
                        />
                        <label className="c-rating__label-read-only-small">
                          <span className="u-visually-hidden">2 stars</span>
                        </label>
                        <input
                          tabIndex="-1"
                          className="c-rating__input"
                          type="radio"
                          name="stars5"
                          value="1"
                          checked={this.state.theReview.stars === 1}
                          readOnly
                        />
                        <label className="c-rating__label-read-only-small">
                          <span className="u-visually-hidden">1 star</span>
                        </label>
                      </fieldset>
                    </div>

                    {this.state.theReview.comment ? (
                      <div
                        className="c-message-bubble u-margin-bottom-0 u-margin-top-s"
                        onClick={(e) =>
                          toScreen(
                            e,
                            this.props.navigate,
                            "/mav/" +
                              this.state.quote._creator.short_id +
                              "/reviews"
                          )
                        }
                      >
                        <div className="c-message-bubble__copy">
                          "{this.state.theReview.comment}"
                        </div>
                      </div>
                    ) : null}
                  </span>
                </div>
              ) : null}

              {this.state.theReview.stars ? (
                <div className="c-hr u-margin-bottom-s u-margin-top-s"></div>
              ) : null}

              <div className="c-input-wide">
                <label className="c-input-wide__label">Price quoted (£):</label>
                <input
                  type="number"
                  value={this.state.quotePriceForMav}
                  onChange={this.handleInputChange}
                  name="quotePriceForMav"
                  placeholder=""
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  Cost for customer (£):
                </label>
                <input
                  type="number"
                  value={this.state.quote.quoteSpec?.price}
                  onChange={this.handleInputChange}
                  name="quote.quoteSpec.price"
                  placeholder=""
                  className="c-input-wide__input"
                />
              </div>
              <div className="c-input-wide">
                <label className="c-input-wide__label">
                  {this.state.quote.job.commission * 100 ||
                    defaultCommission * 100}
                  % for MAV app (£):
                </label>
                <span>
                  <b>{this.state.quotePriceForCommission}</b>
                </span>
              </div>
              {/*
							<div className="c-input-wide">
								<label className="c-input-wide__label">MAV payment status :</label><span><b>{this.state.quote.job.mavPayment === "cashpaid" ? "Payment received" : this.state.quote.job.mavPayment === 'stripetransfercomplete' ? "Stripe transfer complete" : this.state.quote.job.mavPayment === "banktransfercomplete" ? "Bank transfer complete" : "-"}</b></span>
							</div>
*/}

              <div className="c-input-wide">
                <div className="c-input-wide__label">Number of men:</div>
                <select
                  className="c-input-wide__input"
                  value={this.state.quote.quoteSpec.numberMen}
                  onChange={this.handleInputChange}
                  name="quote.quoteSpec.numberMen"
                >
                  <option value="">Please select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
              <div className="c-input-wide">
                <div className="c-input-wide__label">Number of vans:</div>
                <select
                  className="c-input-wide__input"
                  value={this.state.quote.quoteSpec.numberVans}
                  onChange={this.handleInputChange}
                  name="quote.quoteSpec.numberVans"
                >
                  <option value="">Please select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </form>
          </div>

          <div className="l-col-33">
            <AuditNotes
              ref="auditNotes"
              notes={this.state.quote.notes}
              updateNotes={this.updateNotes}
            />
          </div>
        </div>

        {this.state.quote.quoteSpec.images.length != 0 ? (
          <div className="c-hr"></div>
        ) : (
          ""
        )}

        <div className="l-row">
          {Object.keys(this.state.quote.quoteSpec.images).map(
            this.renderImages
          )}
        </div>
      </div>
    );
  }
}

Quote.contextTypes = {
  router: PropTypes.object,
};

Quote.propTypes = {
  userDetails: PropTypes.object.isRequired,
  quoteId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Quote);
